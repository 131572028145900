<template>
  <div class="contents">
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.spacing"
        prop="spacing"
        name="spacing"
        label="Spacing"
        type="number"
        min="0"
        step="10"
        append="px"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
